import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

import NavMenu from './NavMenu';

const liff = window.liff;

export class CouponInfo2 extends Component {
    static displayName = CouponInfo2.name;

    constructor(props) {
        super(props);

        this.state = {
            link01: "/Dashboard",
            link02: "/CouponRedeem",
            link03: "/CouponExpire"
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "Udc6a06c1dfb0a2d100e2f2e8c97cc552";
            //let LineuserPicture = "./media/default-user.png";            
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start 
                    //Check this coupon first
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                CouponId: this.props.location.state.couponId
                            }
                        )
                    };

                    await fetch('/api/couponupdate1', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                this.setState({
                                    userLineID: LineuserId,
                                    pictureUrl: LineuserPicture,
                                    Firstname: json.firstname,
                                    Lastname: json.lastname,
                                    couponId: this.props.location.state.couponId,
                                    couponUrl: this.props.location.state.couponUrl,
                                    couponType: this.props.location.state.couponType
                                });
                            }
                            else {
                                //this coupon shared or redeemed hide that 2 function
                                this.setState({
                                    userLineID: LineuserId,
                                    pictureUrl: LineuserPicture,
                                    Firstname: json.firstname,
                                    Lastname: json.lastname,
                                    couponId: this.props.location.state.couponId,
                                    couponUrl: this.props.location.state.couponUrl,
                                    couponType: this.props.location.state.couponType,
                                    Css01: "btn btn-lg btn-block mb-0 btn-border-trans d-none",
                                    Css02: "btn btn-lg btn-block mb-0 btn-register-2 d-none"
                                });
                            }
                        });
                    //action - end
                }
            });
        }
    }

    render() {        
        var tranlist = [];
        tranlist.push(
            <div className="mt-5 mb-0" key="1">
                <div className="p-0" style={{ backgroundColor: '#ccfdec' }}>
                    <div className="row p-0 m-0">
                        <div className="col-12 mt-0 p-0"> <img src={require("./media/e-coupon-text-2.png")} alt="Happy Holi-yays" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="p-0" style={{ backgroundColor: '#ccfdec' }}>
                    <div className="row p-0 m-0">
                        <div className="col-12 p-3 mt-1">
                            <p className="pt-0 text-end" style={{ color: '#0b0c0c' }}>หมดเขต : <span style={{ fontWeight: 600 }}>8 ธ.ค. 67 เวลา 23.59 น.</span></p>
                            <p className="mb-1" style={{ color: '#0b0c0c' }}><span style={{ fontWeight: 500 }}>คูปองส่วนลด 20% สำหรับเครื่องดื่ม
                                เมื่อซื้อคู่เค้กหรือแซนด์วิช</span></p>
                            <p style={{ color: '#0b0c0c', fontSize: '85%' }}>คูปองส่วนลดเครื่องดื่ม 20% สำหรับเครื่องดื่มสตาร์บัคส์ ขนาด 12 ออนซ์ ขึ้นไป เมื่อซื้อคู่กับเค้กหรือแซนด์วิชประเภทใดก็ได้ที่ร่วมรายการ 1 ชิ้น</p>
                            <p className="mb-1" style={{ color: '#0b0c0c', fontWeight: 600 }}>เงื่อนไขการใช้คูปอง</p>
                            <ul style={{ color: '#0b0c0c', fontSize: '80%', marginLeft: '-20px' }}>
                                <li>คูปองนี้ใช้เป็นส่วนลดเครื่องดื่ม 20% เมื่อซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ขนาด 12 ออนซ์ขึ้นไป คู่กับเค้กหรือแซนด์วิชประเภทใดก็ได้ที่ร่วมรายการ 1 ชิ้น กรณีลูกค้าซื้อเค้กหรือแซนด์วิช 1 ชิ้น และเครื่องดื่ม 2 แก้ว ลูกค้าจะได้รับส่วนลดเครื่องดื่มเพียง 1 แก้ว (แก้วที่ราคาน้อยกว่า)</li>
                                <li>เครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ได้แก่ เครื่องดื่มสตาร์บัคส์ทุกประเภท, กาแฟ Starbucks Reserve™, เครื่องดื่มไอศกรีมทุกประเภท รวมถึงเครื่องดื่ม Customization ที่ลูกค้าเลือก (ยกเว้นน้ำผลไม้ทุกชนิด, เครื่องดื่มบรรจุขวดทุกประเภท และเครื่องดื่มแอลกอฮอล์ทุกประเภท)</li>
                                <li>ผู้ที่ได้รับสิทธิ์สามารถใช้คูปองได้ที่ร้านสตาร์บัคส์ทุกสาขาทั่วประเทศ รวมถึงสาขาสนามบิน (ยกเว้นบริการ Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table และ Delivery ทุกประเภท)</li>
                                <li>1 คูปองสามารถใช้ได้เพียง 1 สิทธิ์เท่านั้น</li>
                                <li>คูปองนี้ไม่สามารถใช้ร่วมกับส่วนลดอื่น ๆ ยกเว้นส่วนลดแก้วส่วนตัว (Personal Cup) และไม่สามารถแลกเป็นเงินสดได้</li>
                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</li>
                            </ul>
                            <div className="row m-0 mb-4 p-1">
                                <div className="col-6 p-1 mt-1">
                                    <div className="d-grid">
                                        <Link className="btn btn-lg btn-block mb-0 btn-share" to={{
                                            pathname: "/CouponDoShared",
                                            state: {
                                                couponId: this.state.couponId,
                                                couponType: this.state.couponType,
                                                couponUrl: this.state.couponUrl
                                            }
                                        }}>แชร์เพื่อน
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-6 p-1 mt-1">
                                    <div className="d-grid">
                                        <Link to={{ pathname: this.state.couponUrl }} className="btn btn-lg btn-block mb-0 btn-register-2 shadow-none" target="_blank">กดใช้คูปอง</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        return (
            <div className="form-signin shadow bg-otherpage1">
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <NavMenu pictureUrl={this.state.pictureUrl} Firstname={this.state.Firstname} Lastname={this.state.Lastname} />

                            {tranlist}
                        </>
                        :
                        <>
                            <div className="form-enter mt-0">
                                <form>
                                    <div className="text-center" style={{ height: '220px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '150px' }} />
                                </form>
                            </div>
                            <div className="position-relative d-none">
                                <div className="bg-footer" style={{ bottom: '0px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                }                
            </div>

        );
    }
}
