import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

const liff = window.liff;

export class Term extends Component {
    static displayName = Term.name;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            //this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId,
                        pictureUrl: LineuserPicture
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                <div className="position-relative">
                    <div className="bg-top" style={{ top: '20px' }}><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                </div>
                <h2 className="h5 text-center mt-4 pt-2 mb-3">
                    <img src={require("./media/head-term.png")} alt="ข้อกำหนดและเงื่อนไขของกิจกรรม" className="img-fluid" style={{ width: '70%' }} />
                </h2>
                <div className="box-bg-white-radius-more pt-4 shadow">
                    <div className="box-content-term" style={{ height: '350px', maxHeight: '350px' }}>
                        <div className="small pt-0 ps-3 pe-3" style={{ color: '#000000' }}>
                            <ol style={{ paddingLeft: '10px' }}>
                                <li>โปรโมชั่นนี้ จัดขึ้นที่ Application LINE บน LINE  @StarbucksThailand  โดยเงื่อนไขที่จัดทำขึ้นสำหรับผู้ที่มีสิทธิเข้าร่วมกิจกรรมตามเงื่อนไขในข้อ 3 จะได้รับ Starbucks  Privilege E-Coupon ในรูปแบบ Digital Coupon ตั้งแต่วันที่ 26 พฤศจิกายน  2567 – วันที่ 15  ธันวาคม 2567 ระยะเวลา 3 สัปดาห์ ได้แก่สัปดาห์ที่ 1 จำนวน 5 ใบ สัปดาห์ที่ 2 จำนวน 5 ใบ และสัปดาห์ที่ 3  จำนวน 5 ใบเพื่อนำไปใช้ซื้ออาหารและเครื่องดื่มที่ร้านสตาร์บัคส์ทุกสาขา ตามรายละเอียดที่กำหนดไว้ในข้อ 7<strong><br />
                                </strong></li>
                                <li>ผู้ร่วมกิจกรรมจะต้องเป็นเพื่อนกับ Starbucks บน Application LINE และลงทะเบียนเพื่อร่วมกิจกรรม โดยระบุ ชื่อ-นามสกุล เบอร์โทรศัพท์ และอีเมล พร้อมทั้งรับทราบข้อตกลงเงื่อนไขในการเข้าร่วมกิจกรรม,  เงื่อนไขของโปรโมชั่น และนโยบายข้อมูลส่วนบุคคล<br />
                                    <strong /></li>
                                <li>กิจกรรมนี้สำหรับ <br />
                                    -
                                    ผู้ที่ไม่เคยเป็นเพื่อนกับ  Starbucks บน Application LINE แต่ได้ดำเนินการเพิ่ม  Starbucks เป็นเพื่อนใน Application LINE ตั้งแต่วันที่ 26 พฤศจิกายน 2567  – 15 ธันวาคม  2567<br />
                                    -
                                    ผู้ที่เคยเป็นเพื่อนกับ  Starbucks แต่ได้ยกเลิกการเป็นเพื่อน (Block) กับ Starbucks ก่อนวันที่ 26 พฤศจิกายน 2567 – 15 ธันวาคม  2567 และต่อมาได้เพิ่ม Starbucks เป็นเพื่อนอีกครั้ง ระหว่างวันที่ 26 พฤศจิกายน 2567 – 15 ธันวาคม  2567<strong><br />
                                    </strong></li>
                                <li>คูปองนี้ จะได้รับที่ Application LINE บน LINE @StarbucksThailand  เท่านั้น โดยรองรับการใช้งานผ่านแอปพลิเคชัน  LINE เวอร์ชัน 12.18.0 ขึ้นไป บนระบบปฏิบัติการ iOS และ Android<br />
                                    <strong> </strong></li>
                                <li>หลังจากกรอกข้อมูลในข้อที่ 2 เป็นที่เรียบร้อยแล้ว  หากท่านได้รับสิทธิตามเงื่อนไขที่กำหนด ท่านจะได้รับคูปองภายใน 1 ชั่วโมง โดยอัตโนมัติ  ผ่านทาง Application LINE  ทั้งนี้ระยะเวลาการจัดส่งคูปองเป็นระยะเวลาโดยประมาณเท่านั้น  ท่านรับทราบและยอมรับว่าระยะเวลาที่ท่านจะได้รับคูปองนั้น  อาจเกินกว่าระยะเวลาที่ได้ประมาณไว้  ซึ่งอาจเกิดจากการมีผู้เข้าร่วมกิจกรรมเป็นจำนวนมาก เป็นต้น สำหรับคูปองในสัปดาห์ถัดไป  ระบบจะทำการจัดส่งคูปองโดยอัตโนมัติในวันแรกของสัปดาห์นั้น  ๆ โดยช่วงเวลาในการจัดส่งอยู่ที่ 00:01 – 03:00 น.<strong /></li>
                                <li>กรณีที่ท่านได้รับ Starbucks Privilege E-Coupon ไปแล้ว  และได้ทำการยกเลิกการใช้งาน Application LINE คูปองที่ท่านได้รับไปแล้วนั้น  จะไม่สามารถใช้งานได้และไม่สามารถนำกลับมาใช้งานที่ Application LINE บน LINE  @StarbucksThailand ใหม่ของท่านได้เช่นกัน<br />
                                </li>
                                <li>Starbucks Privilege E-Coupon ระยะเวลา 3 สัปดาห์  จำนวน 15 ใบ <br />
                                    มีเงื่อนไขคูปอง 3 แบบดังนี้<br />
                                    <p><br />
                                        7.1 คูปองส่วนลดสัปดาห์ที่  1 <br />
                                        <strong>คูปองส่วนลด </strong><strong>50%  สำหรับเครื่องดื่มแก้วที่ 2 (จำนวน 5 ใบ)<br />
                                        </strong>คูปองส่วนลด 50% สำหรับเครื่องดื่มแก้วที่  2 เมื่อซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ที่ร่วมรายการ<br />
                                        ระยะเวลาการรับและใช้งานคูปอง ตั้งแต่วันที่ 26 พฤศจิกายน  2567 – 1 ธันวาคม  2567 เวลา 23:59 น.<br />
                                        <a name="_heading=h.9yfvarafdlix" /><br />
                                        เงื่อนไขการใช้คูปอง<br />
                                        - คูปองนี้ใช้เป็นส่วนลด 50% สำหรับเครื่องดื่มแก้วที่ 2 ประเภทใดและขนาดใดก็ได้  ในราคาที่เท่ากับ หรือน้อยกว่าแก้วแรกที่ซื้อ <br />
                                        - เครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ได้แก่ เครื่องดื่มสตาร์บัคส์ทุกประเภท,  กาแฟ Starbucks Reserve™, เครื่องดื่มไอศกรีมทุกประเภท รวมถึงเครื่องดื่ม  Customization ที่ลูกค้าเลือก (ยกเว้นเครื่องดื่มบรรจุขวดทุกประเภท และเครื่องดื่มแอลกอฮอล์ทุกประเภท)<br />
                                        - ผู้ที่ได้รับสิทธิ์สามารถใช้คูปองได้ที่ร้านสตาร์บัคส์ทุกสาขาทั่วประเทศ  รวมถึงสาขาสนามบิน (ยกเว้นบริการ Drive Thru, Mobile Order-ahead &amp; Pick Up at  Store, Mobile Order to Table และ Delivery ทุกประเภท)<br />
                                        - 1 คูปองสามารถใช้ได้เพียง  1 สิทธิ์เท่านั้น<br />
                                        - คูปองนี้ไม่สามารถใช้ร่วมกับส่วนลดอื่น ๆ รวมถึงส่วนลดแก้วส่วนตัว (Personal  Cup) และไม่สามารถแลกเป็นเงินสดได้<br />
                                        - เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</p>
                                    <p><br />
                                        7.2 คูปองส่วนลดสัปดาห์ที่  2 <br />
                                        <strong>คูปองส่วนลดเครื่องดื่ม </strong><strong>20%  สำหรับเครื่องดื่ม เมื่อซื้อคู่เค้กหรือแซนด์วิช  (จำนวน 5 ใบ)</strong><strong><br />
                                        </strong>คูปองส่วนลดเครื่องดื่ม 20%  สำหรับเครื่องดื่มสตาร์บัคส์ ขนาด 12 ออนซ์ ขึ้นไป <br />
                                        เมื่อซื้อคู่กับเค้กหรือแซนด์วิชประเภทใดก็ได้ที่ร่วมรายการ 1 ชิ้น<br />
                                        ระยะเวลาการรับและใช้งานคูปอง ตั้งแต่วันที่ 2 ธันวาคม 2567  – 8 ธันวาคม 2567 เวลา 23:59 น. </p>
                                    <p>เงื่อนไขการใช้คูปอง<br />
                                        - คูปองนี้ใช้เป็นส่วนลดเครื่องดื่ม  20% เมื่อซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ขนาด 12 ออนซ์ขึ้นไป  คู่กับเค้กหรือแซนด์วิชประเภทใดก็ได้ที่ร่วมรายการ 1 ชิ้น กรณีลูกค้าซื้อเค้กหรือแซนด์วิช 1 ชิ้น และเครื่องดื่ม 2 แก้ว  ลูกค้าจะได้รับส่วนลดเครื่องดื่มเพียง 1 แก้ว  (แก้วที่ราคาน้อยกว่า)<br />
                                        - เครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ได้แก่  เครื่องดื่มสตาร์บัคส์ทุกประเภท, กาแฟ Starbucks Reserve™,  เครื่องดื่มไอศกรีมทุกประเภท รวมถึงเครื่องดื่ม Customization ที่ลูกค้าเลือก  (ยกเว้นเครื่องดื่มบรรจุขวดทุกประเภท  และเครื่องดื่มแอลกอฮอล์ทุกประเภท)<br />
                                        - ผู้ที่ได้รับสิทธิ์สามารถใช้คูปองได้ที่ร้านสตาร์บัคส์ทุกสาขาทั่วประเทศ  รวมถึงสาขาสนามบิน (ยกเว้นบริการ Drive Thru, Mobile Order-ahead &amp; Pick Up at  Store, Mobile Order to Table และ Delivery ทุกประเภท)<br />
                                        - 1 คูปองสามารถใช้ได้เพียง 1 สิทธิ์เท่านั้น<br />
                                        - คูปองนี้ไม่สามารถใช้ร่วมกับส่วนลดอื่น ๆ ยกเว้นส่วนลดแก้วส่วนตัว (Personal  Cup) และไม่สามารถแลกเป็นเงินสดได้<br />
                                        - เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด<br />
                                        <br />
                                        7.3 คูปองส่วนลดสัปดาห์ที่  3 <br />
                                        <strong>คูปองส่วนลด </strong><strong>10%  สำหรับซื้อสินค้าสตาร์บัคส์ประเภทใดก็ได้</strong><strong> (จำนวน </strong><strong>5 ใบ)</strong><strong><br />
                                        </strong>คูปองส่วนลด 10% สำหรับซื้อสินค้าสตาร์บัคส์ประเภทใดก็ได้ <br />
                                        ระยะเวลาการรับและใช้งานคูปอง ตั้งแต่วันที่ 9 ธันวาคม 2567  – 15 ธันวาคม 2567 เวลา 23.59 น.<br />
                                        <br />
                                        เงื่อนไขการใช้คูปอง <br />
                                        - คูปองนี้ใช้เป็นส่วนลดสำหรับซื้อสินค้าสตาร์บัคส์ประเภทใดก็ได้  ยกเว้นเครื่องดื่มแอลกอฮอล์ทุกประเภท<br />
                                        - ผู้ที่ได้รับสิทธิ์สามารถใช้คูปองได้ที่ร้านสตาร์บัคส์ทุกสาขาทั่วประเทศ  รวมถึงสาขาสนามบิน (ยกเว้นบริการ Drive Thru, Mobile Order-ahead &amp; Pick Up at  Store, Mobile Order to Table และ Delivery ทุกประเภท)<br />
                                        - 1 คูปองสามารถใช้ได้เพียง 1 สิทธิ์เท่านั้น<br />
                                        - คูปองนี้ไม่สามารถใช้ร่วมกับส่วนลดอื่น ๆ ยกเว้นส่วนลดแก้วส่วนตัว (Personal  Cup) และไม่สามารถแลกเป็นเงินสดได้<br />
                                        - เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด<br />
                                    </p></li>
                                <li>คูปองส่วนลดที่ท่านได้รับ สามารถส่งต่อให้เพื่อนของท่านได้  โดยการส่งผ่านทาง Application LINE จากบัญชีผู้ใช้ (User account) ในชื่อเมนู  “แชร์ให้เพื่อน” คูปอง จะสามารถใช้ได้เพียงครั้งเดียวเท่านั้น  ซึ่งจะเป็นไปตามเงื่อนไข 1 คูปองสามารถใช้ได้เพียง 1 สิทธิ์เท่านั้น<br />
                                </li>
                                <li>การแจกคูปอง 1 บัญชีผู้ใช้  (User account) สามารถกดรับสิทธิ์ได้ตลอดระยะเวลากิจกรรม  โดยลงทะเบียนสัปดาห์ใดก็จะได้รับคูปองประจำสัปดาห์นั้น ๆ  และสัปดาห์ถัดไปจนจบระยะเวลากิจกรรม <br />
                                </li>
                                <li>เมื่อต้องการใช้สิทธิ์  โปรดแสดงหน้าคูปองกับพนักงานก่อนทำการสั่งซื้อทุกครั้ง<br />และสแกนบาร์โค้ดยืนยันการใช้สิทธิ์คูปองที่จุดชำระเงิน  โดยไม่สามารถแคปเจอร์หน้าจอมาใช้งานได้</li>
                                <li>เมื่อสแกนบาร์โค้ดยืนยันการใช้สิทธิ์คูปอง ลูกค้าจะต้องใช้คูปองทันที ไม่สามารถเก็บไว้ใช้ภายหลังได้  และระบบจะขึ้นคำว่า “Redeemed” (ใช้คูปองแล้ว) โดยอัตโนมัติ  พร้อมระบุวันและเวลาที่กดรับสิทธิ์<br />
                                </li>
                                <li>คูปองนี้ สามารถใช้สิทธิ์ได้เพียงครั้งเดียวเท่านั้น  หากลูกค้ากดยืนยันการใช้คูปองแล้ว จะไม่สามารถใช้คูปองได้อีกในทุกกรณี<br />
                                </li>
                                <li>คูปองไม่สามารถจำหน่าย แลก ทอน  เปลี่ยนเป็นเงินสดหรือของรางวัลอื่นได้<br />
                                </li>
                                <li>คูปองส่วนลดไม่สามารถใช้ร่วมกับส่วนลดอื่น ๆ ของร้านได้<br />
                                </li>
                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ  กำหนด<br />
                                </li>
                                <li>หากมีข้อสงสัยหรือต้องการสอบถามเงื่อนไขในกิจกรรม กรุณาติดต่อได้ที่  ฝ่ายดูแลลูกค้า 02-339-0996 ทุกวันจันทร์ – อาทิตย์ ตั้งแต่เวลา 9:00 น. – 18:00 น.</li>
                            </ol>
                            <p><strong>คำจำกัดความในกิจกรรมนี้</strong></p>
                            <ol style={{ paddingLeft: '10px' }}>
                                <li>“กิจกรรม” หมายถึง กิจกรรมแอปพลิเคชั่นไลน์ (Application LINE) @StarbucksThailand  ซึ่งจัดขึ้นโดย บริษัท คอฟฟี่ คอนเซ็ปต์ รีเทล จำกัด<br />
                                    ตั้งแต่วันที่  26 พฤศจิกายน 2567 –  15 ธันวาคม 2567 โดยแบ่งเป็น<br />
                                    - สัปดาห์ที่ 1 คูปองส่วนลด 50% สำหรับเครื่องดื่มแก้วที่  2 (จำนวน 5 ใบ) <br />
                                    <p>มีระยะเวลาการรับและใช้งานคูปอง  ตั้งแต่วันที่ 26 พฤศจิกายน 2567 –  1 ธันวาคม 2567 เวลา 23:59 น. <br />
                                        - สัปดาห์ที่ 2 คูปองส่วนลดซื้อเครื่องดื่ม 20% สำหรับเครื่องดื่มสตาร์บัคส์  ขนาด 12 ออนซ์ขึ้นไปเมื่อซื้อคู่กับเค้กหรือแซนด์วิชประเภทใดก็ได้ที่ร่วมรายการ  1 ชิ้น (จำนวน 5 ใบ)<br />
                                        มีระยะเวลาการรับและใช้งานคูปอง ตั้งแต่วันที่ 2 ธันวาคม 2567 – 8 ธันวาคม 2567 เวลา 23:59 น.<br />
                                        - สัปดาห์ที่ 3 คูปองส่วนลด 10% สำหรับซื้อสินค้าสตาร์บัคส์ประเภทใดก็ได้ (จำนวน 5 ใบ)<br />
                                        มีระยะเวลาการรับและใช้งานคูปอง ตั้งแต่วันที่ 9 ธันวาคม 2567 – 15 ธันวาคม 2567 เวลา 23:59 น.<br />
                                        หรือวันอื่นใดที่บริษัทฯ  จะประกาศเปลี่ยนแปลงในอนาคต<br />
                                    </p>
                                </li>
                                <li>“ข้อตกลงและเงื่อนไข” (Terms and Conditions) หมายถึง  ข้อตกลงและเงื่อนไขฉบับนี้<br />
                                </li>
                                <li>“โฆษณา” (Advertising) หรือ “การโฆษณา” (Ads) หมายถึง  โฆษณาที่สร้างโดยผู้สนับสนุนเพื่อส่งเสริมการขาย หรือเพื่อกิจกรรมการตลาดต่าง ๆ  ของสินค้าหรือบริการของผู้สนับสนุนโดยผ่านเว็บไซต์<br />
                                </li>
                                <li>“แอปพลิเคชั่นไลน์ (Application LINE) @StarbucksThailand” หมายถึง  โปรแกรมที่ถูกสร้างขึ้นบนระบบปฏิบัติการแอนดรอยด์ (Android) ระบบปฏิบัติการไอโอเอส  (iOS) หรือระบบปฏิบัติการอื่น ๆ ที่อาจพัฒนาขึ้นในอนาคตเป็นสื่อกลาง  ให้ผู้สนับสนุนประกาศแคมเปญกิจกรรมการตลาดต่าง ๆ กำหนดสิทธิ์  กำหนดเงื่อนไขและข้อกำหนด รวมถึงการโฆษณาต่าง ๆ ให้กับผู้ใช้งาน (User)  ที่ยินยอมตกลงการเข้าร่วมกิจกรรม<br />
                                </li>
                                <li>“แคมเปญ” (Campaign) หมายถึง กิจกรรมส่งเสริมการตลาด  ที่ผู้สนับสนุนประกาศลงบนเว็บไซต์หรือโปรแกรมสำหรับให้ผู้ใช้งานร่วมกิจกรรม  หรือแลกรับของรางวัลได้ภายในระยะเวลาที่ผู้สนับสนุนกำหนด  ภายใต้ข้อตกลงและเงื่อนไขของแต่ละแคมเปญ<br />
                                </li>
                                <li>“ระบบ” (System) หมายถึง  ฐานข้อมูลและโปรแกรมการทำงานของแอปพลิเคชั่นไลน์ @StarbucksThailand<br />
                                </li>
                                <li>“ผู้ใช้งาน” (User) หมายถึง ท่านผู้ซึ่งเข้าชมและใช้งาน  รวมไปถึงการร่วมกิจกรรมต่าง ๆ บนแอปพลิเคชั่นไลน์ @StarbucksThailand<br />
                                </li>
                                <li>“บริษัทฯ” และ/หรือ “Starbucks” หมายถึง บริษัท คอฟฟี่ คอนเซ็ปต์  รีเทล จำกัด<br />
                                </li>
                                <li>“สิทธิ์” (Privilege) หมายถึง สิทธิ์ที่ Starbucks  เป็นผู้กำหนดในการได้มา เพื่อใช้ร่วมกิจกรรม แลกรับสินค้า บริการ บัตรกำนัล คูปอง หรือส่วนลดจากแคมเปญที่ประกาศไว้</li>
                            </ol>
                            <p>ผู้เข้าร่วมกิจกรรมได้อ่านและเข้าใจ  และตกลงยินยอมรับทราบตามเงื่อนไขข้างต้นนี้  โดยท่านได้เลือกยอมรับข้อกำหนดและเงื่อนไขในการเข้าร่วมกิจกรรม  ก่อนเริ่มเข้าร่วมกิจกรรมเป็นที่เรียบร้อยแล้ว</p>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0 justify-content-center index2">
                    <div className="col-6 mt-3 text-center">
                        <div className="d-grid">
                            <Link to="/HomeRegis" className="btn mt-0 mb-3 btn-register-2">ปิด</Link>
                        </div>
                    </div>
                </div>
                {/*<div className="position-relative">*/}
                {/*    <div className="bg-footer" style={{ bottom: '-85px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>*/}
                {/*</div>*/}
            </div>
        );
    }
}
