import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';

import { Home } from './components/Home';
import { HomeRegis } from './components/HomeRegis';
import { Home2 } from './components/Home2';
import { HomeThank } from './components/HomeThank';

import { Faq } from './components/Faq';
import { FaqObj } from './components/FaqObj';
import { Term } from './components/Term';

import { NotInLine } from './components/NotInLine';
import { Mistake } from './components/Mistake';
import { MistakeLine } from './components/MistakeLine';

import { Dashboard } from './components/Dashboard';

import { Coupon } from './components/Coupon';
import { CouponExpire } from './components/CouponExpire';
import { CouponRedeem } from './components/CouponRedeem';

import { CouponInfo } from './components/CouponInfo';
import { CouponInfo2 } from './components/CouponInfo2';
import { CouponInfo3 } from './components/CouponInfo3';

import { CouponSoldOut3 } from './components/CouponSoldOut3';

import { CouponDoShared } from './components/CouponDoShared';
import { CouponErr01Shared } from './components/CouponErr01Shared';
import { CouponErr02Redeemed } from './components/CouponErr02Redeemed';

import { CouponRedeemForTest } from './components/CouponRedeemForTest';

import './components/style/custom.css';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/homeregis' component={HomeRegis} />
            <Route path='/home2' component={Home2} />
            <Route path='/homethank' component={HomeThank} />

            <Route path='/faq' component={Faq} />
            <Route path='/faqobj' component={FaqObj} />
            <Route path='/term' component={Term} />

            <Route path='/notinline' component={NotInLine} />
            <Route path='/mistake' component={Mistake} />
            <Route path='/mistakeline' component={MistakeLine} />

            <Route path='/dashboard' component={Dashboard} />

            <Route path='/coupon' component={Coupon} />
            <Route path='/couponexpire' component={CouponExpire} />
            <Route path='/couponredeem' component={CouponRedeem} />

            <Route path='/couponinfo' component={CouponInfo} />
            <Route path='/couponinfo2' component={CouponInfo2} />
            <Route path='/couponinfo3' component={CouponInfo3} />

            <Route path='/coupondoshared' component={CouponDoShared} />
            <Route path='/couponerr01shared' component={CouponErr01Shared} />
            <Route path='/couponerr02redeemed' component={CouponErr02Redeemed} />

            <Route path='/couponsoldout3' component={CouponSoldOut3} />

            <Route path='/couponredeemfortest' component={CouponRedeemForTest} />           
      </Layout>
    );
  }
}
