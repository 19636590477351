import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class MistakeLine extends Component {
    static displayName = MistakeLine.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                <div className="position-relative">
                    <div className="bg-top"><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                </div>
                <div className="form-enter mt-4 pt-3 ps-3 pe-3 pb-0" style={{ minHeight: '650px' }}>
                    <h2 className="text-center mb-5">
                        <img src={require("./media/head-s4.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '70%' }} />
                    </h2>
                    <div className="fieldset-form mt-0 mb-2">
                        <div className="text-center mb-0">
                            <div className="mt-4 ps-0 pb-4 pe-0">
                                <div className="text-center" style={{ minHeight: '590px' }}>
                                    <br />
                                    <h2 className="pt-5 mt-5">มีข้อผิดพลาดเกิดขึ้น - [Line]</h2>
                                    <br />
                                    <Link to="/">กรุณาลองใหม่อีกครั้ง</Link>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="bg-footer" style={{ bottom: '-44px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                </div>
            </div>
        );
    }
}