import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

const liff = window.liff;

export class CouponDoShared extends Component {
    static displayName = CouponDoShared.name;

    constructor(props) {
        super(props);

        this.state = {
            doShared:" ",
            flexcouponimg: "",
            css01: "btn btn-lg btn-block mb-0 btn-register-2",
            css02: "btn btn-lg btn-block mb-0 btn-register-2",
            css03: "btn btn-lg btn-block mb-0 btn-register-2",
            transaction: []
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    let fncouponId = this.props.location.state.couponId;
                    let fncouponUrl = this.props.location.state.couponUrl;
                    let fncouponType = this.props.location.state.couponType;


                    this.setState({
                        userLineID: LineuserId,
                        pictureUrl: LineuserPicture,
                        couponId: this.props.location.state.couponId,
                        couponUrl: this.props.location.state.couponUrl,
                        couponType: this.props.location.state.couponType
                    });

                    //Check this coupon first
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                CouponId: this.props.location.state.couponId
                            }
                        )
                    };

                    await fetch('/api/couponupdate1', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                this.setState({
                                    Firstname: json.firstname,
                                    Lastname: json.lastname,
                                    doShared: "OK"
                                });                                
                            }
                            else {
                                this.setState({
                                    Firstname: json.firstname,
                                    Lastname: json.lastname,
                                    doShared: "NO"
                                });

                                //this coupon shared or redeemed hide that 2 function
                                //couponStatusRedeemed, couponStatusShared
                                if (json.couponStatusRedeemed === "Yes") {
                                    this.props.history.push('/CouponErr02Redeemed')
                                }

                                if (json.couponStatusShared === "Yes") {
                                    this.props.history.push('/CouponErr01Shared')
                                }                               
                            }
                        });

                    if (this.state.doShared === "OK") {
                        //Share Target Picker - Start
                        switch (this.props.location.state.couponType) {
                            default:
                            case "1":
                                this.setState({
                                    flexcouponimg: "https://stbth11.starbuckscampaign.com/media/flexcoupon1.png",
                                    flexcoupontxt1: "ส่วนลด 50%",
                                    flexcoupontxt2: "สำหรับเครื่องดื่มแก้วที่สอง",
                                    flexcoupontxt3: "ตั้งแต่วันที่ 26 พ.ย. 67 - 1 ธ.ค. 67",
                                    css01: "btn btn-lg btn-block mb-0 btn-register-2",
                                    css02: "btn btn-lg btn-block mb-0 btn-register-2 d-none",
                                    css03: "btn btn-lg btn-block mb-0 btn-register-2 d-none"
                                });
                                break;
                            case "2":
                                this.setState({
                                    flexcouponimg: "https://stbth11.starbuckscampaign.com/media/flexcoupon2.png",
                                    flexcoupontxt1: "ส่วนลด 20% สำหรับเครื่องดื่ม",
                                    flexcoupontxt2: "เมื่อซื้อเค้กหรือแซนด์วิช",
                                    flexcoupontxt3: "ตั้งแต่วันที่ 2 ธ.ค. 67 - 8 ธ.ค. 67",
                                    css01: "btn btn-lg btn-block mb-0 btn-register-2 d-none",
                                    css02: "btn btn-lg btn-block mb-0 btn-register-2",
                                    css03: "btn btn-lg btn-block mb-0 btn-register-2 d-none"
                                });
                                break;
                            case "3":
                                this.setState({
                                    flexcouponimg: "https://stbth11.starbuckscampaign.com/media/flexcoupon3.png",
                                    flexcoupontxt1: "คูปองส่วนลด 10%",
                                    flexcoupontxt2: "สินค้าสตาร์บัคส์ทุกประเภท",
                                    flexcoupontxt3: "ตั้งแต่วันที่ 9 ธ.ค. 67 - 15 ธ.ค. 67",
                                    css01: "btn btn-lg btn-block mb-0 btn-register-2 d-none",
                                    css02: "btn btn-lg btn-block mb-0 btn-register-2",
                                    css03: "btn btn-lg btn-block mb-0 btn-register-2 d-none"
                                });
                                break;
                        }

                        const result = await liff.shareTargetPicker([
                            {
                                "type": "flex",
                                "altText": "คุณได้รับ Starbucks Privilege E-Coupon คลิกเพื่อใช้เลย!",
                                "contents": {
                                    "type": "bubble",
                                    "hero": {
                                        "type": "image",
                                        "url": this.state.flexcouponimg,
                                        "size": "full",
                                        "aspectRatio": "1:1",
                                        "action": {
                                            "type": "uri",
                                            "uri": this.state.couponUrl
                                        },
                                        "aspectMode": "cover"
                                    },
                                    "body": {
                                        "type": "box",
                                        "layout": "vertical",
                                        "backgroundColor": "#ccfdec",
                                        "contents": [
                                            //{
                                            //    "type": "text",
                                            //    "text": "Happy Holi-yays",
                                            //    "size": "xs",
                                            //    "align": "center"
                                            //},
                                            {
                                                "type": "text",
                                                "text": "คุณได้รับ Starbucks Privilege E-Coupon",
                                                "color": "#484DBE",
                                                "size": "xs",
                                                "align": "center"
                                            },
                                            {
                                                "type": "text",
                                                "text": this.state.flexcoupontxt1,
                                                "color": "#484DBE",
                                                "align": "center"
                                            },
                                            {
                                                "type": "text",
                                                "text": this.state.flexcoupontxt2,
                                                "color": "#484DBE",
                                                "align": "center"
                                            },
                                            {
                                                "type": "text",
                                                "text": this.state.flexcoupontxt3,
                                                "color": "#484DBE",
                                                "size": "xs",
                                                "align": "center"
                                            }
                                        ]
                                    },
                                    "footer": {
                                        "type": "box",
                                        "layout": "vertical",
                                        "backgroundColor": "#ccfdec",
                                        "spacing": "sm",
                                        "contents": [
                                            {
                                                "type": "button",
                                                "style": "primary",
                                                "height": "sm",
                                                "action": {
                                                    "type": "uri",
                                                    "label": "คลิกเพื่อใช้คูปอง",
                                                    "uri": this.state.couponUrl
                                                },
                                                "color": "#c22939"
                                            },
                                            {
                                                "type": "button",
                                                "style": "link",
                                                "height": "sm",
                                                "action": {
                                                    "type": "uri",
                                                    "label": "ลงทะเบียนเข้าร่วมกิจกรรม",
                                                    "uri": "https://qr.ilov.io/qxeya8"
                                                },
                                                "color": "#484DBE"
                                            },
                                            {
                                                "type": "spacer",
                                                "size": "sm"
                                            }
                                        ],
                                        "flex": 0
                                    }
                                }
                            }
                        ])
                        if (result) {
                            //Message sent -> update date shared
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify(
                                    {
                                        CouponId: this.state.couponId
                                    }
                                )
                            };

                            fetch('/api/couponshared', requestOptions)
                                .then(response => response.json())
                                .then(json => {
                                    if (json.responsecode === "OK") {
                                        //member exists - set 'userLineID'
                                        this.setState({
                                            sharedCoupon: "Shared-Ok"
                                        });
                                    }
                                });
                        }
                        else {
                            //Message not sent
                            switch (fncouponType) {
                                default:
                                case "1":
                                    this.props.history.push({
                                        pathname: "/CouponInfo",
                                        state: {
                                            couponId: fncouponId,
                                            couponUrl: fncouponUrl,
                                            couponType: fncouponType
                                        }
                                    });
                                    break;
                                case "2":
                                    this.props.history.push({
                                        pathname: "/CouponInfo2",
                                        state: {
                                            couponId: fncouponId,
                                            couponUrl: fncouponUrl,
                                            couponType: fncouponType
                                        }
                                    });
                                    break;
                                case "3":
                                    this.props.history.push({
                                        pathname: "/CouponInfo3",
                                        state: {
                                            couponId: fncouponId,
                                            couponUrl: fncouponUrl,
                                            couponType: fncouponType
                                        }
                                    });
                                    break;
                            }                            
                        }
                    //Share Target Picker - end
                    }                    
                }                
            });
        }
    }

    render() {        
        return (
            <div className="form-signin shadow bg-home">
                {
                    (this.state.sharedCoupon && this.state.sharedCoupon !== '')
                        ?
                        <>
                            <div className="position-relative">
                                <div className="bg-top"><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                            </div>
                            <div className="form-enter mt-4 pt-3 ps-3 pe-3 pb-0" style={{ minHeight: '650px' }}>
                                <h2 className="text-center mb-5">
                                    <img src={require("./media/head-s4.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '70%' }} />
                                </h2>
                                <div className="fieldset-form mt-2 mb-2">
                                    <div className="text-center mb-0">
                                        <div className="mt-4 ps-0 pb-4 pe-0">
                                            <img src={require("./media/text-shared.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className="row text-center mt-0 mb-2 pt-0 justify-content-center">
                                        <div className="col-7 p-0">
                                            <div className="d-grid">
                                                <div style={{ height: '0px' }} />
                                                <Link to="/Coupon" className="btn mb-3 btn-register-2">คูปองของฉัน</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '-44px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>                            
                        </>
                        :
                        <>
                            <div className="form-enter mt-0">
                                <form>
                                    <div className="text-center" style={{ height: '220px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '0px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                }
            </div>
        );
    }
}
