import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

const liff = window.liff;

export class Faq extends Component {
    static displayName = Faq.name;

    constructor(props) {
        super(props);

        this.state = {};
    }


    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            //this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId,
                        pictureUrl: LineuserPicture
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                <div className="position-relative">
                    <div className="bg-top" style={{ top: '20px' }}><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                </div>
                <h2 className="h5 text-center mt-4 pt-2 mb-3">
                    <img src={require("./media/head-faq.png")} alt="คำถามที่พบบ่อย" className="img-fluid" style={{ width: '70%' }} />
                </h2>
                <div className="box-bg-white-radius-more pt-4 shadow">
                    <div className="box-content-term" style={{ height: '350px', maxHeight: '350px' }}>
                        <div className="small pt-0 ps-3 pe-3" style={{ color: '#c22939' }}>
                            <ol style={{ paddingLeft: '10px' }}>
                                <li><span style={{ fontWeight: 500 }}>วิธีการเข้าร่วมกิจกรรมทำอย่างไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>เพิ่มเพื่อน @StarbucksThailand บน Application LINE ลงทะเบียนเข้าร่วมกิจกรรมโดยระบุ ชื่อ-นามสกุล เบอร์โทรศัพท์ และอีเมล พร้อมทั้งรับทราบข้อตกลงเงื่อนไขในการเข้าร่วมกิจกรรม, เงื่อนไขของโปรโมชั่น และนโยบายความเป็นส่วนตัว กรณีเคยลงทะเบียนรับ Starbucks Privilege E-Coupon จากกิจกรรมต่าง ๆ บน Application LINE จะไม่ต้องกรอกข้อมูลเพื่อลงทะเบียนซ้ำ</span></li>
                                <li><span style={{ fontWeight: 500 }}>กิจกรรมนี้รองรับการใช้งานด้วยอุปกรณ์บนระบบปฏิบัติการใดบ้าง</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>โดยรองรับการใช้งานผ่านแอปพลิเคชัน LINE เวอร์ชัน 12.18.0 ขึ้นไป บนระบบปฏิบัติการ iOS และ Android</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากปัจุบันได้ทำการยกเลิกการเป็นเพื่อน (Block) @StarbucksThailand แล้วจะสามารถเข้าร่วมกิจกรรมได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>สามารถเข้าร่วมกิจกรรมนี้ได้ เพียงแค่ทำการปลด Block (unblock) และเพิ่ม Starbucks เป็นเพื่อนอีกครั้ง</span></li>
                                <li><span style={{ fontWeight: 500 }}>สิทธิพิเศษนี้มีถึงวันที่เท่าไหร่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ลงทะเบียนและรับสิทธิ์ Starbucks Privilege E-Coupon ในรูปแบบ Digital Coupon <br />
                                        ได้ตั้งแต่วันที่ 26 พฤศจิกายน 2567 – 15 ธันวาคม 2567 <br />
                                        <br />
                                        โดยสัปดาห์ที่ 1 เริ่มตั้งแต่วันที่ 26 พฤศจิกายน 2567 – 1 ธันวาคม 2567<br />
                                        <br />
                                        สัปดาห์ที่ 2 เริ่มตั้งแต่วันที่ 2 ธันวาคม 2567 – 8 ธันวาคม 2567 <br />
                                        <br />
                                        สัปดาห์ที่ 3 เริ่มตั้งแต่วันที่ 9 ธันวาคม 2567 – 15 ธันวาคม 2567</span></li>
                                <li><span style={{ fontWeight: 500 }}>สิทธิพิเศษมีอะไรบ้าง</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>เมื่อร่วมกิจกรรมลงทะเบียนรับสิทธิ์ Starbucks Privilege E-Coupon ท่านจะได้รับ E-Coupon จำนวน 15 ใบ ตั้งแต่วันที่ 26 พฤศจิกายน 2567 – 15 ธันวาคม 2567  โดยแต่ละสัปดาห์จะได้รับคูปองดังนี้<br />
                                        <br />
                                        สัปดาห์ที่ 1 คูปองส่วนลด 50% สำหรับเครื่องดื่มแก้วที่ 2 (จำนวน 5 ใบ) มีระยะเวลาการรับและใช้งานคูปอง ตั้งแต่วันที่ 26 พฤศจิกายน 2567 – 1 ธันวาคม 2567 เวลา 23:59 น.<br />
                                        <br />
                                        สัปดาห์ที่ 2 คูปองส่วนลดซื้อเครื่องดื่ม 20% สำหรับเครื่องดื่มสตาร์บัคส์ ขนาด 12 ออนซ์ขึ้นไป เมื่อซื้อคู่กับเค้กหรือแซนด์วิชประเภทใดก็ได้ที่ร่วมรายการ 1 ชิ้น (จำนวน 5 ใบ) มีระยะเวลาการรับและใช้งานคูปอง ตั้งแต่วันที่ 2 ธันวาคม 2567 – 8 ธันวาคม 2567 เวลา 23:59 น.<br />
                                        <br />
                                        สัปดาห์ที่ 3 คูปองส่วนลด 10% ต่อบิล สำหรับซื้อสินค้าสตาร์บัคส์ประเภทใดก็ได้ (จำนวน 5 ใบ) มีระยะเวลาการรับและใช้งานคูปอง ตั้งแต่วันที่ 9 ธันวาคม 2567 – 15 ธันวาคม 2567 เวลา 23:59 น.</span></li>
                                <li><span style={{ fontWeight: 500 }}>ท่านจะได้รับ Starbucks Privilege E-Coupon เมื่อไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ท่านจะได้รับคูปองภายใน 1 ชั่วโมง โดยอัตโนมัติผ่านทาง LINE สำหรับคูปองในสัปดาห์ถัดไป ระบบจะทำการจัดส่งคูปองโดยอัตโนมัติในวันแรกของสัปดาห์นั้น ๆ โดยช่วงเวลาในการจัดส่งอยู่ที่ 00:01 – 03:00 น. โดยสามารถตรวจสอบได้ที่เมนู “คูปองของฉัน”</span>
                                </li>
                                <li><span style={{ fontWeight: 500 }}>เมื่อเข้าร่วมกิจกรรมแล้วท่านจะได้รับคูปองอย่างไรและเป็นจำนวนเท่าไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ท่านจะได้รับ Starbucks Privilege E-Coupon ได้แก่สัปดาห์ที่ 1 จำนวน 5 ใบ สัปดาห์ที่ 2 จำนวน 5 ใบ และสัปดาห์ที่ 3 จำนวน 5 ใบ ตามระยะเวลาที่เข้าร่วมกิจกรรม และใช้งานได้ภายในระยะเวลาที่กำหนด<br />
                                        ท่านที่เข้าร่วมกิจกรรมระหว่างวันที่ 26 พฤศจิกายน 2567 – 1 ธันวาคม 2567 จะได้รับคูปองของสัปดาห์ที่ 1, 2 และ 3<br />
                                        ท่านที่เข้าร่วมกิจกรรมระหว่างวันที่ 2 ธันวาคม 2567 – 8 ธันวาคม 2567 จะได้รับคูปองของสัปดาห์ที่ 2 และ 3<br />
                                        ท่านที่เข้าร่วมกิจกรรมระหว่างวันที่ 9 ธันวาคม 2567 – 15 ธันวาคม 2567 จะได้รับคูปองของสัปดาห์ที่ 3</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon มีอายุการใช้งานนานเท่าไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>คูปองแต่ละสัปดาห์มีอายุการใช้งานดังนี้<br /><br />
                                        สัปดาห์ที่ 1 รับคูปองส่วนลด 50% สำหรับเครื่องดื่มแก้วที่ 2 (จำนวน 5 ใบ) ได้ตั้งแต่วันที่ 26 พฤศจิกายน 2567 – 1 ธันวาคม 2567 และหมดอายุการใช้งานในวันที่ 1 ธันวาคม 2567 เวลา 23:59 น. <br />
                                        <br />
                                        สัปดาห์ที่ 2 รับคูปองส่วนลดเครื่องดื่ม 20% สำหรับเครื่องดื่มสตาร์บัคส์ ขนาด 12 ออนซ์ขึ้นไป เมื่อซื้อคู่กับเค้กหรือแซนด์วิชประเภทใดก็ได้ที่ร่วมรายการ 1 ชิ้น (จำนวน 5 ใบ) ได้ตั้งแต่วันที่ 2 ธันวาคม 2567 – 8 ธันวาคม 2567 และหมดอายุการใช้งานในวันที่ 8 ธันวาคม 2567 เวลา 23:59 น. <br />
                                        <br />
                                        สัปดาห์ที่ 3 คูปองส่วนลด 10% สำหรับซื้อสินค้าสตาร์บัคส์ประเภทใดก็ได้ (จำนวน 5 ใบ) ได้ตั้งแต่วันที่ 9 ธันวาคม 2567 – 15 ธันวาคม 2567 และหมดอายุการใช้งานในวันที่ 15 ธันวาคม 2567 เวลา 23:59 น.</span></li>
                                <li><span style={{ fontWeight: 500 }}>จำกัดจำนวน Starbucks Privilege E-Coupon ต่อ 1 บัญชี LINE หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>จำกัดสิทธิ์คูปองสูงสุด 15 ใบต่อ 1 บัญชี LINE โดยสามารถกดลงทะเบียนสัปดาห์ใด ก็จะได้รับคูปองประจำสัปดาห์นั้น ๆ และจะได้รับคูปองของสัปดาห์ถัดไปจนจบระยะเวลากิจกรรม</span></li>
                                <li><span style={{ fontWeight: 500 }}>สามารถใช้สิทธิ์ Starbucks Privilege E-Coupon ได้ผ่านช่องทางใดบ้าง</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>การใช้สิทธิ์ Starbucks Privilege E-Coupon สามารถใช้สิทธิ์ได้ดังนี้ <br /><br />
                                        สัปดาห์ที่ 1 คูปองส่วนลด 50% สำหรับเครื่องดื่มแก้วที่ 2 สำหรับเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ที่ร่วมรายการ (จำนวน 5 ใบ) สามารถใช้คูปองได้ที่ร้านสตาร์บัคส์ทุกสาขาทั่วประเทศ รวมถึงสาขาสนามบิน (ยกเว้นบริการ Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table และ Delivery ทุกประเภท) <br /><br />
                                        สัปดาห์ที่ 2 คูปองส่วนลดเครื่องดื่ม 20% สำหรับเครื่องดื่มสตาร์บัคส์ ขนาด 12 ออนซ์ขึ้นไป เมื่อซื้อคู่กับเค้กหรือแซนด์วิชประเภทใดก็ได้ที่ร่วมรายการ 1 ชิ้น (จำนวน 5 ใบ) สามารถใช้คูปองได้ที่ร้านสตาร์บัคส์ทุกสาขาทั่วประเทศ รวมถึงสาขาสนามบิน (ยกเว้นบริการ Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table และ Delivery ทุกประเภท)<br /><br />
                                        สัปดาห์ที่ 3 คูปองส่วนลด 10% สำหรับซื้อสินค้าสตาร์บัคส์ประเภทใดก็ได้ (จำนวน 5 ใบ) สามารถใช้คูปองได้ที่ร้านสตาร์บัคส์ทุกสาขาทั่วประเทศ รวมถึงสาขาสนามบิน  (ยกเว้นบริการ Drive Thru, Mobile Order-ahead & Pick Up at Store, Mobile Order to Table และ Delivery ทุกประเภท)</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon สามารถแคปเจอร์หน้าจอ เพื่อใช้สิทธิ์ส่วนลดได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ไม่สามารถแคปเจอร์หน้าจอมาใช้งาน เมื่อต้องการใช้สิทธิ์ส่วนลด Starbucks Privilege E-Coupon โปรดแสดงหน้าคูปองกับพนักงานก่อนทำการสั่งซื้อทุกครั้ง และสแกนบาร์โค้ดเพื่อรับสิทธิ์ที่จุดชำระเงิน</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากกดใช้ Starbucks Privilege E-Coupon และระบบปรากฏคำว่า “Redeemed” (ใช้คูปองแล้ว) โดยตั้งใจหรือไม่ตั้งใจ สามารถยกเลิกการใช้คูปอง เพื่อให้กลับมาพร้อมใช้ได้งานหรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>Starbucks Privilege E-Coupon แต่ละใบ สามารถใช้งานได้เพียงครั้งเดียวเท่านั้น หากกดยืนยันการใช้คูปองแล้วจะไม่สามารถใช้คูปองที่ปรากฏคำว่า “Redeemed” (ใช้คูปองแล้ว) ได้อีกในทุกกรณี</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon สามารถส่งให้เพื่อนใช้งานได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>Starbucks Privilege E-Coupon สามารถแชร์ให้เพื่อนได้ โดยการส่งผ่านทาง Application LINE เมนู “แชร์ให้เพื่อน” หากท่านหรือเพื่อนมีการกดใช้คูปองจนปรากฏคำว่า “Redeemed” (ใช้คูปองแล้ว) จะสามารถใช้ได้เพียงครั้งเดียวเท่านั้น</span></li>
                                <li><span style={{ fontWeight: 500 }}>สามารถ Share Coupon ให้เพื่อนได้อย่างไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ท่านสามารถกดปุ่ม “แชร์เพื่อน” ในหน้าคูปอง และเลือกรายชื่อเพื่อนที่ต้องการส่งให้ หลังจากนั้นระบบจะส่งข้อความพร้อมลิงก์กดรับคูปองผ่าน LINE ไปยังรายชื่อนั้น</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon ใช้ร่วมกับส่วนลดโปรโมชั่นอื่น ๆ ของร้านได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>คูปองส่วนลดไม่สามารถใช้ร่วมกับส่วนลดโปรโมชั่นอื่น ๆ ของร้านได้</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากได้รับ Starbucks Privilege E-Coupon ไปแล้ว และยกเลิกการใช้งาน Account บัญชีผู้ใช้งาน LINE ID คูปองยังสามารถใช้งานได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>คูปองที่ได้รับไปแล้วจะไม่สามารถใช้งานได้ และไม่สามารถนำกลับมาใช้งานที่ Application LINE ใหม่ของท่านได้เช่นกัน</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากได้รับ Starbucks Privilege E-Coupon ไปแล้ว และทำการ Block @Starbucks Thailand คูปองยังสามารถใช้งานได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>หากท่านทำการกดปลด Block (Unblock) @StarbucksThailand ท่านสามารถกลับมาใช้งานคูปองได้อีกครั้ง และคูปองที่ได้รับไปแล้วจะยังคงอยู่จนถึงระยะเวลาสิ้นสุดโปรโมชั่น</span></li>
                                <li><span style={{ fontWeight: 500 }}>สามารถติดต่อสอบถามข้อมูลของโปรโมชั่นนี้ผ่านช่องทางใดได้บ้าง</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>หากมีข้อสงสัยหรือต้องการสอบถามเงื่อนไขในกิจกรรม กรุณาติดต่อได้ที่ ฝ่ายดูแลลูกค้า 02-339-0996  ทุกวันจันทร์ – อาทิตย์ ตั้งแต่เวลา 9.00 น. – 18.00 น. Facebook : Starbucks Thailand<br />
                                        Email: customercomment@coffee-concepts.co.th</span></li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0 justify-content-center index2">
                    <div className="col-6 mt-3 text-center">
                        <div className="d-grid">
                            <Link to="/" className="btn mt-0 mb-3 btn-register-2">ปิด</Link>
                        </div>
                    </div>
                </div>
                {/*<div className="position-relative">*/}
                {/*    <div className="bg-footer" style={{ bottom: '-85px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>*/}
                {/*</div>*/}
            </div>
        );
    }
}
