import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

const liff = window.liff;

export class HomeThank extends Component {
    static displayName = HomeThank.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide"
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x1";            
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                <div className="position-relative">
                    <div className="bg-top"><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                </div>
                <div className="form-enter mt-4 pt-3 ps-3 pe-3 pb-0" style={{ minHeight: '650px' }}>
                    <h2 className="text-center mb-5">
                        <img src={require("./media/head-s4.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '70%' }} />
                    </h2>
                    <div className="fieldset-form mt-0 mb-2">
                        <div className="text-center mb-0">
                            <div className="mt-4 ps-0 pb-4 pe-0">
                                <img src={require("./media/text-register-done.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '100%' }} />
                            </div>
                        </div>
                        <div className="row text-center mt-5 mb-2 pt-0 justify-content-center">
                            <div className="col-7 p-0">
                                <div className="d-grid">
                                    <div style={{ height: '0px' }} />
                                    <Link to="/Dashboard" className="btn mb-3 btn-register-2">คูปองของฉัน</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="bg-footer" style={{ bottom: '-44px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                </div>
            </div>
        );
    }
}
