import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

const liff = window.liff;

export class FaqObj extends Component {
    static displayName = FaqObj.name;

    constructor(props) {
        super(props);

        this.state = {};
    }


    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            //this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId,
                        pictureUrl: LineuserPicture
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                <div className="position-relative">
                    <div className="bg-top" style={{ top: '20px' }}><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                </div>
                <h2 className="h5 text-center mt-4 pt-2 mb-3">
                    <img src={require("./media/head-objective.png")} alt="วัตถุประสงค์ในการให้ความยินยอม" className="img-fluid" style={{ width: '70%' }} />
                </h2>
                <div className="box-bg-white-radius-more pt-4 shadow">
                    <div className="box-content-term" style={{ height: '450px', maxHeight: '450px' }}>
                        <div className="small pt-0 ps-3 pe-3" style={{ color: '#000000' }}>
                            <p style={{ color: '#000000' }}><strong>เงื่อนไขและคำชี้แจงว่าด้วยความเป็นส่วนตัว</strong></p>
                            <p>บริษัท คอฟฟี่ คอนเซ็ปต์ รีเทล จำกัด (สำนักงานใหญ่) เคารพความเป็นส่วนตัวของท่านและจะคุ้มครองข้อมูลส่วนบุคคลของท่านตามนโยบายความเป็นส่วนตัวของบริษัทฯ</p>
                            <p>คำชี้แจงว่าด้วยความเป็นส่วนตัวนี้ จะแจ้งให้ท่านทราบถึงวิธีการที่เราใช้ในการดูแลรักษาข้อมูลส่วนบุคคลของท่าน เมื่อท่านเข้าร่วมกิจกรรมในครั้งนี้ โดยจะแจ้งให้ท่านทราบเกี่ยวกับสิทธิ์ความเป็นส่วนตัวของท่าน และการคุ้มครองตามกฎหมาย</p>
                            <p>เพื่อการปฏิบัติตามคำชี้แจงว่าด้วยความเป็นส่วนตัวนี้ ข้อมูลส่วนบุคคล หมายถึง ข้อมูลใดๆ เกี่ยวกับบุคคลที่สามารถใช้ระบุตัวบุคคลนั้นได้</p>
                            <p>คำชี้แจงว่าด้วยความเป็นส่วนตัวนี้เป็นส่วนเพิ่มเติมของคำประกาศและนโยบายความเป็นส่วนตัวอื่นๆ ของบริษัทฯ (ถ้ามี)</p>
                            <ol>
                                <li>ผู้เข้าร่วมกิจกรรมผ่าน LINE @StarbucksThailand ยินยอมให้ บริษัท คอฟฟี่ คอนเซ็ปต์ รีเทล จำกัด (สำนักงานใหญ่) นำข้อมูลที่ได้รับจากผู้ร่วมกิจกรรมในครั้งนี้ไปทำการเก็บ รวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลตามวัตถุประสงค์ที่ได้กำหนดไว้ และได้รับอนุญาตจากผู้ร่วมกิจกรรมตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล</li>
                                <li>ผู้ร่วมกิจกรรมยินยอมเปิดเผยข้อมูลส่วนบุคคลเพื่อใช้ในการประมวลผลเพื่อพัฒนาผลิตภัณฑ์และการให้บริการเพื่อวิเคราะห์ตลาดและพฤติกรรมของผู้บริโภคและนําเสนอผลิตภัณฑ์หรือ บริการของบริษัทฯ เพื่อแสดงโฆษณา ข้อเสนอ โปรโมชั่น กิจกรรมส่งเสริมการขาย สิทธิ์ประโยชน์พิเศษ และการบริการของบริษัทฯ</li>
                                <li>บริษัทฯ จะมีการเก็บรวมรวมและใช้ข้อมูลส่วนบุคคลของท่านตามระยะเวลาที่กําหนดไว้ตามกฎหมาย หรือกฎเกณฑ์ที่เกี่ยวข้อง ทั้งนี้ ข้อมูลส่วนบุคคลของท่านจะได้รับการปฏิบัติตาม นโยบายความเป็นส่วนตัว ซึ่งประกาศอยู่บนเว็บไซต์ของบริษัทฯ https://www.starbucks.co.th/th/</li>
                                <li>บริษัทฯ ขอสงวนสิทธิ์ในการปรับปรุงให้ทันสมัย แก้ไข และเปลี่ยนแปลงคําชี้แจงว่าด้วยความเป็นส่วนตัวฉบับนี้ได้ตลอดเวลา โดยการเปลี่ยนแปลงจะมีผลทันทีหลังจากที่การเปลี่ยนแปลง ได้ถูกประกาศบน Application LINE ที่ LINE @StarbucksThailand และ Website ดังนั้น บริษัทฯ แนะนำให้ท่านตรวจสอบการเปลี่ยนแปลงคำชี้แจงนี้เป็นระยะ</li>
                            </ol>
                            <p>ผู้เข้าร่วมกิจกรรมได้อ่านและเข้าใจการขอความยินยอมข้างต้นและตกลงยินยอมรับทราบตามเงื่อนไขข้างต้นนี้ โดยท่านได้เลือกยอมรับ ข้อกำหนดและเงื่อนไข ก่อนเริ่มเข้าร่วมกิจกรรมเป็นที่เรียบร้อยแล้ว</p>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0 justify-content-center index2">
                    <div className="col-6 mt-3 text-center">
                        <div className="d-grid">
                            <Link to="/HomeRegis" className="btn mt-0 mb-3 btn-block btn-register-2">ปิด</Link>
                        </div>
                    </div>
                </div>
                {/*<div className="position-relative">*/}
                {/*    <div className="bg-footer" style={{ bottom: '-85px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>*/}
                {/*</div>*/}
            </div>
        );
    }
}