import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

import myDoc from './media/document.svg'

const liff = window.liff;

export class Home2 extends Component {
    static displayName = Home2.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssinner05: "d-none",
            ModelCssinner06: "d-none",
            Pdpa: "No"
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",            
            ModelCssinner05: "d-none",
            ModelCssinner06: "d-none"
        });
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });        
    }

    async onSubmitForm() {
        let isthisvalid = "yes";        

        if (!this.state.ans01) {
            this.setState({
                ModelCssinner05: ""
            });

            isthisvalid = "no";
        }        

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            if (!this.state.ans02) {
                // POST request using fetch inside useEffect React hook            
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            UserId: this.state.userLineID,
                            Ans01: this.state.ModelCssinner05,
                            Ans02: "No"
                        }
                    )
                };

                const response = await fetch('/api/memberregis2', requestOptions);
                const data = await response.json();

                if (data.responsecode === "OK") {
                    this.props.history.push('/HomeThank')
                }
                else {
                    this.props.history.push({
                        pathname: "/Mistake",
                        state: {
                            ErrorTxt: data.responsemessage
                        }
                    });
                }
            }
            else{
                // POST request using fetch inside useEffect React hook            
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            UserId: this.state.userLineID,
                            Ans01: this.state.ModelCssinner05,
                            Ans02: "Yes"
                        }
                    )
                };

                const response = await fetch('/api/memberregis2', requestOptions);
                const data = await response.json();

                if (data.responsecode === "OK") {
                    this.props.history.push('/HomeThank')
                }
                else {
                    this.props.history.push({
                        pathname: "/Mistake",
                        state: {
                            ErrorTxt: data.responsemessage
                        }
                    });
                }
            }
           
        }
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "Udc6a06c1dfb0a2d100e2f2e8c97cc552";                        
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check member not exists
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberregischeck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //campaign member exists
                                this.props.history.push('/Dashboard')
                            }
                            else {
                                //campaign member not exists - show register check form
                                this.setState({
                                    userLineID: LineuserId,
                                    Mobile: json.mobile,
                                    Email: json.email,
                                    Firstname: json.firstname,
                                    Lastname: json.lastname
                                });
                            }
                        });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="position-relative">
                                <div className="bg-top" style={{ top: '20px' }}><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                            </div>
                            <div className="form-enter mt-3 pb-0 ps-1 pe-1" style={{ minHeight: '730px' }}>
                                <div className="ps-0 pe-0 mt-0 mb-3">
                                    <img src={require("./media/img-kv-page-section-01.png")} alt="Happy Holi-yays" className="img-fluid mb-0" />
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 ps-4">
                                        <div className="form-check ms-2">
                                            <input className="form-check-input" type="checkbox" name="ans01" defaultValue={this.state.ans01} onChange={this.onInputchange} />
                                            <label className="form-check-label text-darkblue"><small>รับทราบ <Link to="/term" style={{ textDecoration: 'underline' }}><strong style={{ fontWeight: 500 }}>ข้อกำหนดและเงื่อนไขของกิจกรรม</strong></Link></small></label>
                                        </div>
                                        <div className="form-check ms-2">  
                                            <input className="form-check-input" type="checkbox" name="ans02" defaultValue={this.state.ans02} onChange={this.onInputchange} />
                                            <label className="form-check-label text-darkblue"><small>รับทราบและตกลงตาม <Link to="/faqobj" style={{ textDecoration: 'underline' }}><strong style={{ fontWeight: 500 }}>วัตถุประสงค์ในการให้ความยินยอม</strong><br />
                                            </Link>และรับทราบ <Link to={{ pathname: "https://www.starbucks.co.th/th/online-policies/privacy-notice/" }} target="_blank" className="text-darkblue" style={{ textDecoration: 'underline' }}><strong style={{ fontWeight: 500 }}>นโยบายข้อมูลส่วนบุคคล</strong></Link></small></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="position-relative mt-3">
                                    <div className="row m-0 p-1 justify-content-center index2">
                                        <div className="col-6 p-0 ps-2 pe-1 text-center">
                                            <input type="button" className="btn mb-3 btn-white2 w-100 shadow-none" defaultValue="ยกเลิก" onClick={this.closeLIFF} />
                                        </div>
                                        <div className="col-6 p-0 ps-1 pe-2 text-center">
                                            <input type="button" className="btn mb-3 btn-register-2 w-100 shadow-none" defaultValue="ร่วมกิจกรรม" onClick={this.onSubmitForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '-5px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>

                            <div>
                                {/*Modal*/}
                                <div id="popup1" className={this.state.ModelCss01}>
                                    <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                    <div className="text-center mt-3 mb-2">
                                        <img src={myDoc} alt="Warning..." width={100} className="img-fluid" />
                                    </div>
                                    <h5 className="text-center">กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน</h5>
                                    <ul className="text-start">
                                        <li className={this.state.ModelCssinner05}>กดรับทราบ ข้อกำหนดและเงื่อนไขของกิจกรรม</li>
                                    </ul>
                                    <div className="text-center mt-3 mb-2">
                                        <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: '65px' }} />
                            <div className="position-relative">
                                <div className="bg-footer" style={{ width: '89%', bottom: 0, marginBottom: 0, left: '6%' }}><img src={require("./media/bg-footer.png")} alt="Happy Holi-yays" className="img-fluid" /></div>
                            </div>                            
                        </>
                        :
                        <>
                            <div className="form-enter mt-0">
                                <form>
                                    <div className="text-center" style={{ height: '220px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '0px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                }                
            </div>

        );
    }
}
