import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

import NavMenu from './NavMenu';
import coupon01 from './media/coupon-text-1-redeem.png'
import coupon02 from './media/coupon-text-2-redeem.png'
import coupon03 from './media/coupon-text-3-redeem.png'
import coupon01s from './media/coupon-text-1-share.png'
import coupon02s from './media/coupon-text-2-share.png'
import coupon03s from './media/coupon-text-3-share.png'
import couponno from './media/no-coupon-used-to.png'

const liff = window.liff;

export class CouponRedeem extends Component {
    static displayName = CouponRedeem.name;

    constructor(props) {
        super(props);

        this.state = {
            link01: "/Dashboard",
            link02: "/CouponRedeem",
            link03: "/CouponExpire"
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "Udc6a06c1dfb0a2d100e2f2e8c97cc552";
            //let LineuserPicture = "./media/default-user.png";                        
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start 
                    //check member not exists
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/membercheckcoupon', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //member exists - set 'userLineID'
                                this.setState({
                                    userLineID: LineuserId,
                                    pictureUrl: LineuserPicture,
                                    Mobile: json.mobile,
                                    Email: json.email,
                                    Firstname: json.firstname,
                                    Lastname: json.lastname,
                                    Coupon1Pending: json.coupon1Pending,
                                    CouponTotal: json.couponTotal,
                                    CampaignEnd: json.campaignEnd,
                                    CampaignPeriod: json.campaignPeriod
                                });
                            }
                            else {
                                //member not exists - show register form
                                this.props.history.push('/')
                            }
                        });

                    if (this.state.CampaignEnd === "Y") {
                        //Campaign push back to home
                        this.props.history.push('/')
                    }

                    //get coupon list - start
                    const requestOptions2 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId,
                                CouponType: this.state.CampaignPeriod
                            }
                        )
                    };
                    await fetch('/api/membercheckcouponlistredeem', requestOptions2)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                transaction: json,
                                jpage: "done"
                            });
                        });
                    //get coupon list - end

                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            //render only jpage -> done

            var tranlist = [];
            var couponimg = "";

            if (this.state.transaction.length >= 1) {
                for (let i = 0; i < this.state.transaction.length; i++) {

                    if (this.state.transaction[i].dateshared != "") {
                        //coupon has shared 
                        if (this.state.transaction[i].coupontype == "1") {
                            if (this.state.transaction[i].dateredeemed != "") {
                                //redeemed
                                couponimg = coupon01;
                            }
                            else {
                                //shared only not redeemed yet
                                couponimg = coupon01s;
                            }
                        } else if (this.state.transaction[i].coupontype == "2") {
                            if (this.state.transaction[i].dateredeemed != "") {
                                //redeemed
                                couponimg = coupon02;
                            }
                            else {
                                //shared only not redeemed yet
                                couponimg = coupon02s;
                            }
                        } else if (this.state.transaction[i].coupontype == "3") {
                            if (this.state.transaction[i].dateredeemed != "") {
                                //redeemed
                                couponimg = coupon03;
                            }
                            else {
                                //shared only not redeemed yet
                                couponimg = coupon03s;
                            }
                        } else {
                            couponimg = coupon01s;
                        }

                        tranlist.push(
                            <div className="row p-0 m-0 shadow mb-3" key={i}>
                                <div className="col-7 p-0 text-left box-e-coupon-info" style={{ backgroundColor: '#f2f0ec' }}>
                                    <div className="row m-0 ps-3 pe-3 pt-2 pb-2 p-10px-small-important" style={{ backgroundColor: '#f2f0ec', height: '50%' }}>
                                        <div className="col-12 p-0">
                                            <p className="text-start mb-0 text-use-coupon2 ms-1">
                                                <span className="text-datetime-1">วันและเวลาที่แชร์คูปอง</span><br />
                                                <span className="text-datetime-2">{this.state.transaction[i].dateshared}</span></p>
                                        </div>
                                    </div>
                                    <div className="row m-0 ps-3 pe-3 pt-2 pb-2 p-10px-small-important" style={{ backgroundColor: '#fcfcfb', height: '50%' }}>
                                        <div className="col-12 p-0">
                                            <p className="text-start mb-0 text-use-coupon2 ms-1">
                                                <span className="text-datetime-1">วันและเวลาที่ใช้คูปอง</span><br />
                                                <span className="text-datetime-2">{this.state.transaction[i].dateredeemed}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 p-0 box-coupon-img">
                                    <img src={couponimg} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" />
                                </div>
                            </div>
                        )
                    }
                    else {
                        if (this.state.transaction[i].coupontype == "1") {
                            couponimg = coupon01;
                        } else if (this.state.transaction[i].coupontype == "2") {
                            couponimg = coupon02;
                        } else if (this.state.transaction[i].coupontype == "3") {
                            couponimg = coupon03;
                        } else {
                            couponimg = coupon01;
                        }

                        tranlist.push(
                            <div className="row p-0 m-0 shadow mb-3" key={i}>
                                <div className="col-7 p-0 text-left box-e-coupon-info">
                                    <div className="row m-0 ps-3 pe-3 p-3">
                                        <div className="col-12 p-0">
                                            <p className="text-start text-use-coupon ms-1">
                                                <span className="text-datetime-3">วันและเวลาที่ใช้คูปอง</span><br />
                                                <span className="text-datetime-2">{this.state.transaction[i].dateredeemed}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 p-0 box-coupon-img">
                                    <img src={couponimg} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" />
                                </div>
                            </div>
                        )
                    }
                }
            }
            else {
                tranlist.push(
                    <div className="mb-4" style={{ minHeight: '320px' }} key="99">
                        <div className="text-center border-radius-10 m-auto mt-4 p-2">
                            <div style={{ height: '130px' }} />
                            <img src={couponno} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '40%' }} />
                        </div>
                        <div style={{ height: '130px' }} />
                    </div>
                )
            }
        }

        return (
            <div className="form-signin shadow pt-top bg-home bg-position-y-50 position-relative">
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <NavMenu pictureUrl={this.state.pictureUrl} Firstname={this.state.Firstname} Lastname={this.state.Lastname} />

                            <div className="mt-2 mb-4">
                                <div className="text-center mt-2">
                                    <div className="pt-4 pt-0 ps-0 pb-0 pe-0">
                                        <img src={require("./media/top-bg-header-1.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '65%' }} />
                                    </div>
                                </div>
                                <div className="form-enter p-3">
                                    <div className="tabs-my-rewards mt-2 mb-3">
                                        <div className="row">
                                            <div className="col-4 text-center p-1" style={{}}>
                                                <Link to={this.state.link01} className="menu-myrewards">คูปองของฉัน</Link>
                                            </div>
                                            <div className="col-4 text-center p-1">
                                                <Link to={this.state.link02} className="active-menu menu-myrewards">คูปองใช้แล้ว <span className="line-tab" /></Link>
                                            </div>
                                            <div className="col-4 text-center p-1">
                                                <Link to={this.state.link03} className="menu-myrewards">คูปองหมดอายุ</Link>
                                            </div>
                                        </div>
                                        <div />
                                    </div>

                                    <div className="row ps-2 pe-2 pb-3">
                                        {tranlist}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="bg-footer" style={{ bottom: '100px', width: '70%', left: '15%' }}><img src={require("./media/bg-footer-01.png")} alt="Happy Holi-yays" className="img-fluid" /></div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-0">
                                <form>
                                    <div className="text-center" style={{ height: '220px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '0px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                }
            </div>

        );
    }
}
