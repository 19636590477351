import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

import NavMenu from './NavMenu';

import coupon02 from './media/e-coupon2-coming.png'
import coupon03 from './media/e-coupon3-coming.png'
import coupon02n from './media/e-coupon-notext-2.png'
import coupon03n from './media/e-coupon-notext-3.png'

const liff = window.liff;

export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
        super(props);

        this.state = {
            link01: "/Dashboard",
            link02: "/CouponRedeem",
            link03: "/CouponExpire",
            coupon1main: "row p-0 m-0 shadow mb-3",
            coupon2main: "row p-0 m-0 shadow mb-3",
            coupon3main: "row p-0 m-0 shadow mb-3",
            coupon1sub: "box-over-e-coupons-text",
            coupon2sub: "box-over-e-coupons-text",
            coupon3sub: "box-over-e-coupons-text",
            coupon1pending: "0",
            coupon2pending: "0",
            coupon3pending: "0",
            coupon2show: coupon02,
            coupon3show: coupon03,
            link01img: "/Coupon",
            link02img: "/",
            link03img: "/"
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "Udc6a06c1dfb0a2d100e2f2e8c97cc552";
            //let LineuserPicture = "./media/default-user.png";            
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start 
                    //check member not exists
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberdashboard', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //member exists - set 'userLineID'
                                this.setState({
                                    userLineID: LineuserId,
                                    pictureUrl: LineuserPicture,
                                    Mobile: json.mobile,
                                    Email: json.email,
                                    Firstname: json.firstname,
                                    Lastname: json.lastname,
                                    Coupon1Pending: json.coupon1Pending,
                                    CouponTotal: json.couponTotal,
                                    CampaignEnd: json.campaignEnd,
                                    CampaignPeriod: json.campaignPeriod
                                });
                            }
                            else {
                                //member not exists - show register form
                                this.props.history.push('/')
                            }
                        });

                    //period check - start
                    if (this.state.CampaignPeriod == "1") {
                        this.setState({
                            coupon1main: "row p-0 m-0 shadow mb-3",
                            coupon2main: "row p-0 m-0 shadow mb-3",
                            coupon3main: "row p-0 m-0 shadow mb-3",
                            coupon1sub: "",
                            coupon2sub: "box-over-e-coupons-text",
                            coupon3sub: "box-over-e-coupons-text",
                            coupon1pending: this.state.Coupon1Pending,
                            coupon2pending: "0",
                            coupon3pending: "0",
                            link01img: "/Coupon",
                            link02img: "/Dashboard",
                            link03img: "/Dashboard"
                        });

                        if (this.state.Coupon1Pending == "0" || this.state.CouponTotal != "5") {
                            this.setState({
                                coupon1sub: "box-over-e-coupons-text"
                            });
                        }
                    } else if (this.state.CampaignPeriod == "2") {
                        this.setState({
                            coupon1main: "row p-0 m-0 shadow mb-3 d-none",
                            coupon2main: "row p-0 m-0 shadow mb-3",
                            coupon3main: "row p-0 m-0 shadow mb-3",
                            coupon1sub: "box-over-e-coupons-text",
                            coupon2sub: "",
                            coupon3sub: "box-over-e-coupons-text",
                            coupon1pending: "0",
                            coupon2pending: this.state.Coupon1Pending,
                            coupon3pending: "0",
                            coupon2show: coupon02n,
                            link01img: "/Dashboard",
                            link02img: "/Coupon",
                            link03img: "/Dashboard"
                        });

                        if (this.state.Coupon1Pending == "0" || this.state.CouponTotal != "5") {
                            this.setState({
                                coupon2sub: "box-over-e-coupons-text"
                            });
                        }
                    } else if (this.state.CampaignPeriod == "3") {
                        this.setState({
                            coupon1main: "row p-0 m-0 shadow mb-3 d-none",
                            coupon2main: "row p-0 m-0 shadow mb-3 d-none",
                            coupon3main: "row p-0 m-0 shadow mb-3",
                            coupon1sub: "box-over-e-coupons-text",
                            coupon2sub: "box-over-e-coupons-text",
                            coupon3sub: "",
                            coupon1pending: "0",
                            coupon2pending: "0",
                            coupon3pending: this.state.Coupon1Pending,
                            coupon3show: coupon03n,
                            link01img: "/Dashboard",
                            link02img: "/Dashboard",
                            link03img: "/Coupon"
                        });

                        if (this.state.Coupon1Pending == "0" || this.state.CouponTotal != "5") {
                            this.setState({
                                coupon3sub: "box-over-e-coupons-text"
                            });
                        }
                    }
                    //period check - end

                    if (this.state.CampaignEnd === "Y") {
                        this.setState({
                            link01: "/CouponSoldOut",
                            link02: "/CouponRedeem",
                            link03: "/CouponExpire"
                        });
                    }
                    //action - end
                }
            });
        }
    }

    render() {
        //This for disable image link
        var tranlist = [];
        var tranlist2 = [];
        var tranlist3 = [];

        if (this.state.coupon1sub == "box-over-e-coupons-text") {
            tranlist.push(
                <div className="col-5 p-0 box-e-coupon-info">
                    <img src={require("./media/e-coupon1.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" />
                </div>
            )
        }
        else {
            tranlist.push(
                <div className="col-5 p-0 box-e-coupon-info">
                    <Link to={this.state.link01img}><img src={require("./media/e-coupon1.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" /></Link>
                </div>
            )
        }

        if (this.state.coupon2sub == "box-over-e-coupons-text") {
            tranlist2.push(
                <div className="col-5 p-0 box-e-coupon-info">
                    <img src={this.state.coupon2show} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" />
                </div>
            )
        }
        else {
            tranlist2.push(
                <div className="col-5 p-0 box-e-coupon-info">
                    <Link to={this.state.link02img}><img src={this.state.coupon2show} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" /></Link>
                </div>
            )
        }

        if (this.state.coupon3sub == "box-over-e-coupons-text") {
            tranlist3.push(
                <div className="col-5 p-0 box-e-coupon-info">
                    <img src={this.state.coupon3show} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" />
                </div>
            )
        }
        else {
            tranlist3.push(
                <div className="col-5 p-0 box-e-coupon-info">
                    <Link to={this.state.link03img}><img src={this.state.coupon3show} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" /></Link>
                </div>
            )
        }

        return (
            <div className="form-signin shadow pt-top bg-home bg-position-y-50 position-relative">
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <NavMenu pictureUrl={this.state.pictureUrl} Firstname={this.state.Firstname} Lastname={this.state.Lastname} />
                            
                            <div className="mt-2 mb-4">
                                <div className="text-center mt-2">
                                    <div className="pt-4 pt-0 ps-0 pb-0 pe-0">
                                        <img src={require("./media/top-bg-header-1.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '65%' }} />
                                    </div>
                                </div>
                                <div className="form-enter p-3">
                                    <div className="tabs-my-rewards mt-2 mb-3">
                                        <div className="row">
                                            <div className="col-4 text-center p-1" style={{}}>
                                                <Link to={this.state.link01} className="active-menu menu-myrewards">คูปองของฉัน <span className="line-tab" /></Link>
                                            </div>
                                            <div className="col-4 text-center p-1">
                                                <Link to={this.state.link02} className="menu-myrewards">คูปองใช้แล้ว</Link>
                                            </div>
                                            <div className="col-4 text-center p-1">
                                                <Link to={this.state.link03} className="menu-myrewards">คูปองหมดอายุ</Link>
                                            </div>
                                        </div>
                                        <div />
                                    </div>

                                    <div className={this.state.coupon1main}>
                                        <div className="col-7 p-0 text-left box-e-coupon-info">
                                            <div className="row m-0 p-1 pt-2 pb-1 position-relative">
                                                <div className="col-12 p-0 text-center">
                                                    <Link to={this.state.link01img}><img src={require("./media/e-coupon1-text-1.png")} alt="คูปองส่วนลด" className="img-fluid mt-0" style={{ width: '90%' }} /></Link>
                                                </div>
                                                <div className="col-12 p-0 mt-2 text-center">
                                                    <Link to={this.state.link01img} className="btn-ecoupons">คูปองพร้อมใช้ <span className="number-e-coupon">{this.state.coupon1pending}</span></Link>
                                                </div>
                                                <div className={this.state.coupon1sub} />
                                            </div>
                                        </div>
                                        {tranlist}
                                    </div>

                                    <div className={this.state.coupon2main}>
                                        <div className="col-7 p-0 text-left box-e-coupon-info">
                                            <div className="row m-0 p-1 pt-2 pb-1 position-relative">
                                                <div className="col-12 p-0 text-center">
                                                    <Link to={this.state.link02img}><img src={require("./media/e-coupon2-text-2.png")} alt="คูปองส่วนลด" className="img-fluid mt-0" style={{ width: '90%' }} /></Link>
                                                </div>
                                                <div className="col-12 p-0 mt-2 text-center">
                                                    <Link to={this.state.link02img} className="btn-ecoupons">คูปองพร้อมใช้ <span className="number-e-coupon">{this.state.coupon2pending}</span></Link>
                                                </div>
                                                <div className={this.state.coupon2sub} />
                                            </div>
                                        </div>
                                        {tranlist2}
                                    </div>

                                    <div className={this.state.coupon3main}>
                                        <div className="col-7 p-0 text-left box-e-coupon-info">
                                            <div className="row m-0 p-1 pt-2 pb-1 position-relative">
                                                <div className="col-12 p-0 text-center">
                                                    <Link to={this.state.link03img}><img src={require("./media/e-coupon3-text-3.png")} alt="คูปองส่วนลด" className="img-fluid mt-0" style={{ width: '90%' }} /></Link>
                                                </div>
                                                <div className="col-12 p-0 mt-2 text-center">
                                                    <Link to={this.state.link03img} className="btn-ecoupons">คูปองพร้อมใช้ <span className="number-e-coupon">{this.state.coupon3pending}</span></Link>
                                                </div>
                                                <div className={this.state.coupon3sub} />
                                            </div>
                                        </div>
                                        {tranlist3}
                                    </div>
                                    <div className="position-relative">
                                        <div className="bg-footer">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-0">
                                <form>
                                    <div className="text-center" style={{ height: '220px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '0px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                }
            </div>

        );
    }
}
