import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

import NavMenu from './NavMenu';
import coupon01 from './media/e-coupon-text-1-couponpage.png'
import coupon02 from './media/e-coupon-text-2-couponpage.png'
import coupon03 from './media/e-coupon-text-3-couponpage.png'

const liff = window.liff;

export class Coupon extends Component {
    static displayName = Coupon.name;

    constructor(props) {
        super(props);

        this.state = {
            link01: "/Dashboard",
            link02: "/CouponRedeem",
            link03: "/CouponExpire"
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "Udc6a06c1dfb0a2d100e2f2e8c97cc552";
            //let LineuserPicture = "./media/default-user.png";            
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start 
                    //check member not exists
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/membercheckcoupon', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //member exists - set 'userLineID'
                                this.setState({
                                    userLineID: LineuserId,
                                    pictureUrl: LineuserPicture,
                                    Mobile: json.mobile,
                                    Email: json.email,
                                    Firstname: json.firstname,
                                    Lastname: json.lastname,
                                    Coupon1Pending: json.coupon1Pending,
                                    CouponTotal: json.couponTotal,
                                    CampaignEnd: json.campaignEnd,
                                    CampaignPeriod: json.campaignPeriod
                                });
                            }
                            else {
                                //member not exists - show register form
                                this.props.history.push('/')
                            }
                        });

                    if (this.state.CampaignEnd === "Y") {
                        //Campaign end push back to home
                        this.props.history.push('/')
                    }

                    if (this.state.Coupon1Pending == "0") {
                        //No coupon left push back to Dashboard
                        this.props.history.push('/Dashboard')
                    }

                    //get coupon list - start
                    const requestOptions2 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId,
                                CouponType: this.state.CampaignPeriod
                            }
                        )
                    };
                    await fetch('/api/membercheckcouponlist', requestOptions2)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                transaction: json,
                                jpage: "done"
                            });
                        });
                    //get coupon list - end

                    //action - end
                }
            });
        }
    }

    render() {        
        if (this.state.jpage === "done") {
            //render only jpage -> done

            var tranlist = [];
            var couponimg = "";
            var couponinfo = "";
            if (this.state.CampaignPeriod == "1") {
                couponimg = coupon01;
                couponinfo = "/CouponInfo";
            } else if (this.state.CampaignPeriod == "2") {
                couponimg = coupon02;
                couponinfo = "/CouponInfo2";
            } else if (this.state.CampaignPeriod == "3") {
                couponimg = coupon03;
                couponinfo = "/CouponInfo3";
            } else {
                couponimg = coupon01;
                couponinfo = "/CouponInfo";
            }

            if (this.state.transaction.length >= 1) {
                for (let i = 0; i < this.state.transaction.length; i++) {
                    tranlist.push(
                        <div className="col-6 p-1" key={i}>
                            <Link to={{
                                pathname: couponinfo,
                                state: {
                                    couponId: this.state.transaction[i].transectionid,
                                    couponType: this.state.transaction[i].coupontype,
                                    couponUrl: this.state.transaction[i].couponurl
                                }
                            }}>
                                <img src={couponimg} alt="Starbucks Privilege E-Coupons" className="img-fluid box-e-coupon-img" />
                            </Link>
                        </div>
                    )
                }                
            }
        }

        return (
            <div className="form-signin shadow pt-top bg-home bg-position-y-50 position-relative">
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <NavMenu pictureUrl={this.state.pictureUrl} Firstname={this.state.Firstname} Lastname={this.state.Lastname} />

                            <div className="mt-2 mb-4">
                                <div className="text-center mt-2">
                                    <div className="pt-4 pt-0 ps-0 pb-0 pe-0">
                                        <img src={require("./media/top-bg-header-1.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '65%' }} />
                                    </div>
                                </div>
                                <div className="form-enter p-3">
                                    <div className="tabs-my-rewards mb-3">
                                        <div className="row">
                                            <div className="col-4 text-center p-1" style={{}}>
                                                <Link to={this.state.link01} className="active-menu menu-myrewards">คูปองของฉัน <span className="number-e-coupon">{this.state.Coupon1Pending}</span> <span className="line-tab" /></Link>
                                            </div>
                                            <div className="col-4 text-center p-1">
                                                <Link to={this.state.link02} className="menu-myrewards">คูปองใช้แล้ว</Link>
                                            </div>
                                            <div className="col-4 text-center p-1">
                                                <Link to={this.state.link03} className="menu-myrewards">คูปองหมดอายุ</Link>
                                            </div>
                                        </div>
                                        <div />
                                    </div>

                                    <div className="row p-0 m-0 mb-3">
                                        {tranlist}                                  
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="bg-footer" style={{ bottom: '100px', width: '70%', left: '15%' }}><img src={require("./media/bg-footer-01.png")} alt="Happy Holi-yays" className="img-fluid" /></div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-0">
                                <form>
                                    <div className="text-center" style={{ height: '220px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '0px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                }                
            </div>

        );
    }
}
