import React, { Component } from 'react';

export class NotInLine extends Component {
    static displayName = NotInLine.name;

    render() {
        return (
            <>                
                <div className="form-signin shadow bg-home">
                    <div className="position-relative">
                        <div className="bg-top"><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                    </div>
                    <div className="form-enter mt-4 pt-3 ps-3 pe-3 pb-0" style={{ minHeight: '650px' }}>
                        <h2 className="text-center mb-5">
                            <img src={require("./media/head-s4.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '70%' }} />
                        </h2>
                        <div className="fieldset-form mt-0 mb-2">
                            <div className="text-center mb-0">
                                <div className="mt-4 ps-0 pb-4 pe-0">
                                    <label className="pt-2 text-black" style={{ fontSize: '16px' }}>ร่วมกิจกรรม Happy Holi-yays ได้ผ่านเบราว์เซอร์ภายในแอปพลิเคชัน LINE เท่านั้น</label>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="bg-footer" style={{ bottom: '-44px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                    </div>
                </div>
            </>
        );
    }
}